
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import AbpBase from '../../../../lib/abpbase'
    import UpdateJobNode from "@/store/entities/jobcontext/updatejobnodes";
    import {_} from 'vue-underscore';

    @Component
    export default class NotifyJobOwnerForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({default: -1}) jobId: number;
        private message: string = '';

        async save() {
            await this.$store.dispatch({
                type: 'job/notifyOwner',
                data: {
                    jobId: this.jobId,
                    message: this.message
                }
            });
            this.message='';
            this.$emit('save-success');
            this.$emit('input', false);
        }

        cancel() {
            this.message = '';
            this.$emit('input', false);
        }

        async visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {

            }
        }
    }
