
    import AbpBase from "../../../../lib/abpbase";
    import {Component, Prop} from "vue-property-decorator";
    import JobDocument from "../../../../store/entities/jobcontext/jobdocument";
    import generatedocument from "@/store/entities/jobcontext/generatedocument";
    import DocumentCustomProperties from "@/views/setting/jobcontext/job/document-custom-properties.vue";

    @Component
    export default class JobDocumentClient extends AbpBase {
        @Prop({default: new JobDocument()}) jobDocument: JobDocument;

        async download(id: number) {
            await this.$store.dispatch('job/downloadJobDocument', id);
        }
    }
